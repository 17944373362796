import request from '@/utils/request'

export default {
  addTeacher(param) {
    return request({
      url: `/youth/yt-teacher/addTeacher/`,
      method: 'post',
      data: param
    })
  },
  get100() {
    return request({
      url: `/youth/yt-teacher/get100/`,
      method: 'get'
    })
  }
}
